.input-with-info {
    position: relative;
  }
  
  .input-field {
    padding-right: 30px;
  }
  
  .info-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .info-text {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    color: red;
  }

  .input-with-info .info-icon:hover + .info-text,
.input-with-info .info-text:hover {
  display: block;
}