.avatar-70 {
    height: 70px;
    width: 70px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    padding: 20px;
}

.user-contacts, .user-infos{ 
    border-radius: 5px;
    transition: 0.7s;
    border: 1pxx solid rgba(0, 0, 0, .5);
    cursor: pointer;
}

.user-contacts:hover {
    box-shadow: rgba(45, 45, 45, 0.12) 0px 0.125rem 0.25rem 0px, rgba(45, 45, 45, 0.16) 0px 0.0625rem 0.1875rem 0px, rgba(45, 45, 45, 0.2) 0px 0px 0.125rem 0px;
}

.underline {
    text-decoration: underline;
}

.border-top {
    border-top: 1px solid rgba(5, 38, 78, 0.5);
}